import React from "react";
import TextElement from "../TextElement/TextElement";
import InputQuestion from "../Question/InputQuestion";
import SelectQuestion from "../Question/SelectQuestion";
import MultiSelectQuestion from "../Question/MultiSelectQuestion";
import TextEditor from "../Question/TextEditor";
import ToolTip from "../ToolTip";
import EndUseBreakdown from "../EndUseBreakdown/EndUseBreakdown";
import { RESPONSE_STATUS_COMPLETED, TABS } from "../../utils/constants";
import ToggleIndicator from "../Accordion/ToggleIndicator";

import "./Page.css";

function evaluateCondition(condition, formValues) {
  if (condition.and) {
    for (let c of condition.and) {
      if (!evaluateCondition(c, formValues)) return false;
    }
    return true;
  }
  if (condition.or) {
    for (let c of condition.or) {
      if (evaluateCondition(c, formValues)) return true;
    }
    return false;
  }

  let fieldValue = formValues[condition.field];
  let gt, gte, lt, lte, eq, includes, contains, neq, notNull, notIn;
  gt = gte = lt = lte = eq = includes = contains = neq = notNull = notIn = true;
  if (condition.gt || condition.gt === 0 || condition.gt === "")
    gt = fieldValue > condition.gt;
  if (condition.gte || condition.gte === 0 || condition.gte === "")
    gte = fieldValue >= condition.gte;
  if (condition.lt || condition.lt === 0 || condition.lt === "")
    lt = fieldValue < condition.lt;
  if (condition.lte || condition.lte === 0 || condition.lte === "")
    lte = fieldValue <= condition.lte;
  if (condition.eq || condition.eq === 0 || condition.eq === "")
    eq = fieldValue === condition.eq;
  if (condition.neq || condition.neq === 0 || condition.neq === "")
    neq = fieldValue !== condition.neq;
  if (condition.notNull)
    notNull = fieldValue !== undefined && fieldValue !== null;
  if (condition.in) {
    includes = condition.in.indexOf(fieldValue) >= 0;
  }
  if (condition.notIn) {
    notIn = condition.notIn.indexOf(fieldValue) < 0;
  }
  if (condition.contains) {
    if (!fieldValue) {
      contains = false;
    } else {
      contains = fieldValue.indexOf(condition.contains) >= 0;
    }
  }

  return (
    gt &&
    gte &&
    lt &&
    lte &&
    eq &&
    includes &&
    contains &&
    neq &&
    notNull &&
    notIn
  );
}

function evaluateConditions(conditions, formValues, condition) {
  if (conditions === null && condition != null) {
    return evaluateCondition(condition, formValues);
  }
  if (conditions.length === 0) return true;

  let conditionAccepted = false;
  for (let condition of conditions) {
    let operation = condition.operation || "OR";
    let thisConditionAccepted = evaluateCondition(condition, formValues);
    if (operation === "OR")
      conditionAccepted = conditionAccepted || thisConditionAccepted;
    if (operation === "AND")
      conditionAccepted = conditionAccepted && thisConditionAccepted;
  }
  return conditionAccepted;
}

function getConditionalState(conditionalStates, answers) {
  let state = "SHOW";
  if (!conditionalStates || !answers) return state;
  let formValues = {};
  for (let answer of answers) formValues[answer.field] = answer.value;

  for (let conditionalState of conditionalStates) {
    let conditionResult = evaluateConditions(
      conditionalState.conditions,
      formValues,
      conditionalState.condition
    );
    if (conditionResult) return conditionalState.state;
  }
  return state;
}

function RenderContentType({
  session = {},
  validator,
  onAddressChanged,
  onUpdating,
  content,
  disableField,
  survey,
  processedConfig,
  state,
  setState,
}) {
  // Function to check if an element should be visible
  function isElementVisible(element) {
    // Headers are always visible if their parent text section is expanded
    if (element.type === "text") return true;

    const parentText =
      element.parentTextId &&
      processedConfig.find((e) => e._id === element.parentTextId);

    // If parent text has toggled and collapsed, hide everything in its section
    if (parentText?.toggle && !state.expandedSections.has(parentText._id)) {
      return false;
    }

    // For elements under a sub_text header
    if (element.type !== "sub_text") {
      const previousSubText = findPreviousSubText(element);
      if (
        previousSubText?.toggle &&
        !state.expandedSections.has(previousSubText._id)
      ) {
        return false;
      }
    }

    return true;
  }

  // Helper function to find the previous sub_text header for an element
  function findPreviousSubText(element) {
    const elementIndex = processedConfig.findIndex(
      (e) => e._id === element._id
    );
    for (let i = elementIndex - 1; i >= 0; i--) {
      if (processedConfig[i].type === "sub_text") {
        return processedConfig[i];
      }
      if (processedConfig[i].type === "text") {
        break;
      }
    }
    return null;
  }

  function toggleSection(sectionId) {
    const newSet = new Set(state.expandedSections);
    if (newSet.has(sectionId)) {
      newSet.delete(sectionId);
    } else {
      newSet.add(sectionId);
    }
    setState(newSet);
  }

  const isVisible = isElementVisible(content);
  const isExpanded = state.expandedSections.has(content._id);

  switch (content.type) {
    case "text":
      return (
        <div
          key={content._id}
          className={`col-lg-12 col-md-12 col-sm-12 pb-3 ${
            content.state === "HIDE" ? "d-none" : "d-block"
          }`}
          style={{ marginLeft: "-5px" }}
        >
          <div
            className="d-flex"
            style={{ cursor: content.toggle ? "pointer" : "auto" }}
            onClick={() => content.toggle && toggleSection(content._id)}
          >
            {content.toggle && (
              <ToggleIndicator
                isExpanded={isExpanded}
                type={content.toggleType || "caret"}
                className="mt-2"
              />
            )}
            <div>
              {content.title && (
                <h3>
                  <div className="d-flex">
                    <span>{content.title}</span>
                    {content.infoField && (
                      <ToolTip
                        content={<span>{content.infoField}</span>}
                        direction="right"
                      >
                        <i className="bi bi-info-circle icon"></i>
                      </ToolTip>
                    )}
                  </div>
                </h3>
              )}
              {content.body && <TextElement>{content.body}</TextElement>}
            </div>
          </div>
        </div>
      );
    case "html":
      return (
        <div
          key={content._id}
          className={`row ${
            content.state === "HIDE" || !isVisible ? "d-none" : "d-block"
          }`}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
            <h3>
              <div className="d-flex">
                <span>{content.title}</span>
                {content.infoField && (
                  <ToolTip
                    content={<span>{content.infoField}</span>}
                    direction="right"
                  >
                    <i className="bi bi-info-circle icon"></i>
                  </ToolTip>
                )}
              </div>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: content.body }} />
          </div>
        </div>
      );
    case "sub_text":
      return (
        <div
          key={content._id}
          className={`col-lg-12 col-md-12 col-sm-12 pb-3 ${
            content.state === "HIDE" || content.title === "" || !isVisible
              ? "d-none"
              : "d-block"
          }`}
          onClick={() => content.toggle && toggleSection(content._id)}
        >
          <div
            className="d-flex mt-2 mb-0"
            style={{ cursor: content.toggle ? "pointer" : "auto" }}
          >
            {content.toggle && (
              <ToggleIndicator
                isExpanded={isExpanded}
                type={content.toggleType || "caret"}
                className="mt-1"
              />
            )}
            <div>
              <h5>
                <div className="d-flex">
                  <span>{content.title}</span>
                  {content.infoField && (
                    <ToolTip
                      content={<span>{content.infoField}</span>}
                      direction="right"
                    >
                      <i className="bi bi-info-circle icon"></i>
                    </ToolTip>
                  )}
                </div>
              </h5>
              <TextElement>{content.body}</TextElement>
            </div>
          </div>
        </div>
      );
    case "input":
      return (
        <InputQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
          isVisible={isVisible}
        />
      );
    case "text_area":
      return (
        <TextEditor
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
          isVisible={isVisible}
        />
      );
    case "select":
      return (
        <SelectQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          isVisible={isVisible}
        />
      );
    case "multi_select":
      return (
        <MultiSelectQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          isVisible={isVisible}
        />
      );
    case "chart":
      return <EndUseBreakdown survey={survey} isVisible={isVisible} />;
    default:
      return null;
  }
}

function ContentElement({
  onAddressChanged,
  session = {},
  validator,
  disableField,
  onUpdating,
  survey,
  processedConfig,
  state,
  setState,
  content,
}) {
  /* Evaluate Conditional States */
  content.state = getConditionalState(
    content.conditionalState,
    session.answers
  );
  return (
    <div
      className="row"
      style={{ display: content.state === "HIDE" ? "none" : "block" }}
    >
      {content.startHTML && <div dangerouslySetInnerHTML={{ __html: content.startHTML }} />}
      <RenderContentType
        key={`content-element-${content._id}`}
        session={session}
        validator={validator}
        onAddressChanged={onAddressChanged}
        onUpdating={onUpdating}
        content={content}
        disableField={disableField}
        survey={survey}
        processedConfig={processedConfig}
        state={state}
        setState={setState}
      />
      {content.endHTML && <div dangerouslySetInnerHTML={{ __html: content.endHTML }} />}
    </div>
  );
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedSections: new Set(),
    };
  }

  isDisableField = () => {
    const {
      navigationType,
      responseBySessionAndStatus,
      isEditEnabled,
    } = this.props;
    if (
      navigationType === TABS &&
      responseBySessionAndStatus &&
      responseBySessionAndStatus.sessionId &&
      responseBySessionAndStatus.status === RESPONSE_STATUS_COMPLETED
    ) {
      if (isEditEnabled) {
        return false;
      }
      return true;
    }
    return false;
  };

  updateState = (newValue) => {
    this.setState({ expandedSections: newValue });
  };

  render() {
    const {
      children,
      content,
      session,
      onAddressChanged,
      validator,
      onUpdating,
      survey,
      startHTML,
      endHTML,
    } = this.props;

    if (validator) {
      validator.purgeFields();
    }

    // Process the flat config to determine section boundaries
    function processedConfig(formConfig) {
      let currentTextHeader = null;

      return formConfig.map((element, index) => {
        // Update section tracking
        if (element.type === "text") {
          currentTextHeader = element;
        }

        // Find the next relevant header based on current element type
        let nextHeaderIndex = formConfig.length;
        if (element.type === "text") {
          // Find next text header
          for (let i = index + 1; i < formConfig.length; i++) {
            if (formConfig[i].type === "text") {
              nextHeaderIndex = i;
              break;
            }
          }
        } else if (element.type === "sub_text") {
          // Find next sub_text header or text header
          for (let i = index + 1; i < formConfig.length; i++) {
            if (
              formConfig[i].type === "sub_text" ||
              formConfig[i].type === "text"
            ) {
              nextHeaderIndex = i;
              break;
            }
          }
        }

        return {
          ...element,
          parentTextId: currentTextHeader?._id,
          sectionEnd: nextHeaderIndex,
        };
      });
    }

    const configurationWithSections = processedConfig(content);
    return (
      <div className="wrapper min-vh-100 mb-5">
        <div
          className="container min-vh-100 py-2 bg-white pt-3 pb-3"
          style={{ padding: "2rem" }}
        >
          {startHTML && <div dangerouslySetInnerHTML={{ __html: startHTML }} />}
          {configurationWithSections
            .map((content, index) =>
              content.hidden ? null : (
                <ContentElement
                  key={`content-element-${content._id}`}
                  session={session}
                  validator={validator}
                  onAddressChanged={onAddressChanged}
                  onUpdating={onUpdating(content.field)}
                  content={content}
                  disableField={this.isDisableField()}
                  survey={survey}
                  processedConfig={configurationWithSections}
                  state={this.state}
                  setState={this.updateState}
                />
              )
            )
            .filter((c) => c)}
          {endHTML && <div dangerouslySetInnerHTML={{ __html: endHTML }} />}
          {children}
        </div>
      </div>
    );
  }
}

Page.defaultProps = {
  content: [],
  session: {},
};

export default Page;
