import React from "react";
import { Formik, Field } from "formik";
import { Mutation } from "@apollo/client/react/components";
import { ObjectId } from "bson";
import "./InputQuestion.css";
import {
  ADD_SESSION_ANSWER,
  UPDATE_SESSION_ANSWER,
  updateAfterAddSessionAnswer,
  updateAfterUpdateSessionAnswer,
} from "../../apis/survey/schema/session";

import ToolTip from "../ToolTip";

import { generateOptimisticResponse } from "../../utils/question";

class InputQuestion extends React.Component {
  constructor() {
    super();
    this.state = {
      fieldValue: "",
      fieldClass: "",
      setValueFunc: undefined,
    };

    this.handleIntChange = this.handleIntChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleDefaultChange = this.handleDefaultChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleIntChange(event, setFieldValue, field) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setFieldValue(field, event.target.value);
    }
  }

  handleNumberChange(event, setFieldValue, field) {
    const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)?$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setFieldValue(field, event.target.value);
    }
  }

  handleYearChange(event, setFieldValue, field) {
    const re = /^[0-9\b]+$/;
    if (
      event.target.value === "" ||
      (re.test(event.target.value) && event.target.value.length < 5)
    ) {
      setFieldValue(field, event.target.value);
    }
  }

  handleEmailChange(event, setFieldValue, field) {
    const emailRegex = /^[A-Za-z0-9._@-]+$/;
    if (event.target.value === "" || emailRegex.test(event.target.value)) {
      setFieldValue(field, event.target.value);
    }
  }

  handleDefaultChange(event, setFieldValue, field) {
    setFieldValue(field, event.target.value);
  }

  async handleSubmit(executeMutation, values) {
    const {
      content: { _id, field },
      answer,
      session,
      onUpdating,
    } = this.props;
    try {
      onUpdating(true);
      if (answer._id) {
        const optimisticResponse = generateOptimisticResponse(
          answer,
          session,
          values[field],
          "updateAnswer"
        );
        //Not new, send update
        await executeMutation({
          optimisticResponse,
          variables: {
            session: { _id: session._id },
            answer: {
              _id: answer._id,
              value: values[field],
            },
          },
        });
      } else {
        const newAnswer = {
          value: values[field],
          field,
          question: _id,
          _id: new ObjectId().toString(),
        };
        const optimisticResponse = generateOptimisticResponse(
          newAnswer,
          session,
          values[field],
          "addAnswer"
        );
        //Is new, send create
        await executeMutation({
          optimisticResponse,
          variables: {
            session: { _id: session._id },
            answer: newAnswer,
          },
        });
      }
    } finally {
      onUpdating(false);
    }
  }

  isFieldDisabled = () => {
    const { answer, disableField } = this.props;
    if (disableField) return true;
    if (
      ["buildingAddress", "customId"].includes(answer.field) &&
      answer.validation &&
      answer.validation.isValid
    )
      return true;
    return false;
  };

  render() {
    const {
      content,
      answer,
      session,
      validator,
      disableField,
      onUpdating,
      isVisible,
    } = this.props;

    const {
      title,
      body,
      field,
      format,
      state,
      infoField = "",
      required,
      parentTextId,
    } = content;

    let mutation,
      updateAfterMutation,
      initialValues,
      updateVariables = { session: { _id: session._id } };
    if (answer._id) {
      //Not new, send update
      mutation = UPDATE_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterUpdateSessionAnswer(...args, updateVariables);
      initialValues = { [field]: answer.value };
    } else {
      //Is new, send create
      mutation = ADD_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterAddSessionAnswer(...args, updateVariables);
      initialValues = { [`${field}`]: "" };
    }

    /* set validation and character options */
    let validationOptions = "";
    let changeHandler = this.handleDefaultChange;

    if ((title.startsWith("*") || required) && state !== "HIDE") {
      validationOptions += "required";
    }

    if (format === "integer") {
      changeHandler = this.handleIntChange;
    } else if (format === "number") {
      changeHandler = this.handleNumberChange;
    } else if (format === "year") {
      changeHandler = this.handleYearChange;
      validationOptions += "|min:4";
    } else if (format === "email") {
      changeHandler = this.handleEmailChange;
      validationOptions += "|email";
    } else if (format === "phone") {
      // TODO: could add change handler
      validationOptions += "|phone";
    }
    validationOptions = validationOptions.replace(/^\|+|\|+$/g, "");

    return (
      <Mutation mutation={mutation} update={updateAfterMutation}>
        {(executeMutation, { data, loading }) => {
          return (
            <Formik initialValues={initialValues} enableReinitialize={true}>
              {({ errors, values, setFieldValue }) => (
                <form
                  className={`form-group ${
                    state === "HIDE" || !isVisible ? "d-none" : "d-block"
                  }`}
                  style={{ marginLeft: parentTextId ? "35px" : "0" }}
                >
                  <label htmlFor={field}>
                    {title}
                    {infoField && (
                      <ToolTip
                        content={<span>{infoField}</span>}
                        direction="right"
                      >
                        <i className="bi bi-info-circle icon"></i>
                      </ToolTip>
                    )}
                  </label>
                  <Field
                    name={field}
                    className={"form-control " + this.state.fieldClass}
                    placeholder={body}
                    value={values[field]}
                    onChange={(event) => {
                      changeHandler(event, setFieldValue, field);
                    }}
                    onFocus={() => onUpdating(true)}
                    onBlur={() => {
                      this.handleSubmit(executeMutation, values);
                      validator.showMessageFor(field);
                    }}
                    disabled={this.isFieldDisabled()}
                  />
                  {validationOptions !== "" &&
                    validator.message(field, values[field], validationOptions)}
                  {values[field] &&
                    answer.validation &&
                    !answer.validation.isValid && (
                      <div className="srv-validation-message">
                        {answer.validation.message}
                      </div>
                    )}
                </form>
              )}
            </Formik>
          );
        }}
      </Mutation>
    );
  }
}

InputQuestion.defaultProps = {
  content: {},
  session: {},
  answer: {},
};

export default InputQuestion;
