import React, { useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { Formik, Field } from 'formik';
import { ObjectId } from 'bson';
import { Mutation } from '@apollo/client/react/components';
import {
  ADD_SESSION_ANSWER,
  UPDATE_SESSION_ANSWER,
  updateAfterAddSessionAnswer,
  updateAfterUpdateSessionAnswer,
} from '../../apis/survey/schema/session';
import ToolTip from '../ToolTip';
import 'react-quill/dist/quill.snow.css';
import { debounce, head } from 'lodash';
import { generateOptimisticResponse } from '../../utils/question';

const icons = ReactQuill.Quill.import('ui/icons');

icons.header[3] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/>
</svg>`;

icons.header[4] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm7.05371,7.96582v.38477c0,.39648-.165.60547-.46191.60547h-.47314v1.29785a.54085.54085,0,0,1-.605.60547h-.69336a.54085.54085,0,0,1-.605-.60547V12.95605H11.333a.5412.5412,0,0,1-.60547-.60547v-.15332a1.199,1.199,0,0,1,.22021-.748l2.56348-4.05957a.7819.7819,0,0,1,.72607-.39648h1.27637a.54085.54085,0,0,1,.605.60547v3.7627h.33008A.54055.54055,0,0,1,17.05371,11.96582ZM14.28125,8.7207h-.022a4.18969,4.18969,0,0,1-.38525.81348l-1.188,1.80469v.02246h1.5293V9.60059A7.04058,7.04058,0,0,1,14.28125,8.7207Z"/>
</svg>`;

icons.header[5] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M16.74023,12.18555a2.75131,2.75131,0,0,1-2.91553,2.80566,3.908,3.908,0,0,1-2.25537-.68164.54809.54809,0,0,1-.13184-.8252L11.73438,13c.209-.34082.48389-.36328.8252-.1543a2.23757,2.23757,0,0,0,1.1001.33008,1.01827,1.01827,0,0,0,1.1001-.96777c0-.61621-.53906-.97949-1.25439-.97949a2.15554,2.15554,0,0,0-.64893.09961,1.15209,1.15209,0,0,1-.814.01074l-.12109-.04395a.64116.64116,0,0,1-.45117-.71484l.231-3.00391a.56666.56666,0,0,1,.62744-.583H15.541a.54085.54085,0,0,1,.605.60547v.43945a.54085.54085,0,0,1-.605.60547H13.41748l-.04395.72559a1.29306,1.29306,0,0,1-.04395.30859h.022a2.39776,2.39776,0,0,1,.57227-.07715A2.53266,2.53266,0,0,1,16.74023,12.18555ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/>
</svg>`;

icons.header[6] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M14.51758,9.64453a1.85627,1.85627,0,0,0-1.24316.38477H13.252a1.73532,1.73532,0,0,1,1.72754-1.4082,2.66491,2.66491,0,0,1,.5498.06641c.35254.05469.57227.01074.70508-.40723l.16406-.5166a.53393.53393,0,0,0-.373-.75977,4.83723,4.83723,0,0,0-1.17773-.14258c-2.43164,0-3.7627,2.17773-3.7627,4.43359,0,2.47559,1.60645,3.69629,3.19043,3.69629A2.70585,2.70585,0,0,0,16.96,12.19727,2.43861,2.43861,0,0,0,14.51758,9.64453Zm-.23047,3.58691c-.67187,0-1.22168-.81445-1.22168-1.45215,0-.47363.30762-.583.72559-.583.96875,0,1.27734.59375,1.27734,1.12207A.82182.82182,0,0,1,14.28711,13.23145ZM10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Z"/>
</svg>`;

const TextEditor = ({
  content,
  answer,
  session,
  disableField,
  onUpdating,
  isVisible,
}) => {
  const {
    title,
    body,
    field,
    format,
    state,
    infoField = '',
    initialValue = '',
    parentTextId,
  } = content;
  let mutation,
    updateAfterMutation,
    updateVariables = { session: { _id: session._id } };
  if (answer?._id) {
    //Not new, send update
    mutation = UPDATE_SESSION_ANSWER;
    updateAfterMutation = (...args) =>
      updateAfterUpdateSessionAnswer(...args, updateVariables);
  } else {
    //Is new, send create
    mutation = ADD_SESSION_ANSWER;
    updateAfterMutation = (...args) =>
      updateAfterAddSessionAnswer(...args, updateVariables);
  }
  const [value, setValue] = useState(answer?._id ? answer.value : initialValue);
  const handleSubmit = useCallback(
    debounce(async (executeMutation, newValue) => {
      if (value !== newValue) {
        setValue(newValue);
        try {
          onUpdating(true);
          if (answer?._id) {
            const optimisticResponse = generateOptimisticResponse(
              answer,
              session,
              newValue,
              'updateAnswer'
            );
            //Not new, send update
            await executeMutation({
              optimisticResponse,
              variables: {
                session: { _id: session._id },
                answer: {
                  _id: answer._id,
                  value: newValue,
                },
              },
            });
          } else {
            const newAnswer = {
              value: newValue,
              field,
              question: content._id,
              _id: new ObjectId().toString(),
            };
            const optimisticResponse = generateOptimisticResponse(
              newAnswer,
              session,
              newValue,
              'addAnswer'
            );
            //Is new, send create
            await executeMutation({
              optimisticResponse,
              variables: {
                session: { _id: session._id },
                answer: newAnswer,
              },
            });
          }
        } finally {
          onUpdating(false);
        }
      }
    }, 1000),
    [content._id, field, answer, session, onUpdating, setValue]
  );
  return (
    <Mutation mutation={mutation} update={updateAfterMutation}>
      {(executeMutation, { data, loading }) => {
        return (
          <form
            className={`form-group ${
              state === 'HIDE' || !isVisible ? 'd-none' : 'd-block'
            }`}
            style={{ marginLeft: parentTextId ? '35px' : '0' }}
          >
            <label htmlFor={field}>
              {title}
              {infoField && (
                <ToolTip content={<span>{infoField}</span>} direction='right'>
                  <i className='bi bi-info-circle icon'></i>
                </ToolTip>
              )}
            </label>
            <ReactQuill
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike', 'link'],
                  [
                    { header: '1' },
                    { header: '2' },
                    { header: '3' },
                    { header: '4' },
                    { header: '5' },
                    { header: '6' },
                  ],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                ],
              }}
              theme='snow'
              value={value}
              readOnly={disableField}
              onChange={(content, _delta, source) => {
                if (source == 'user') {
                  handleSubmit(executeMutation, content);
                }
              }}
            />
          </form>
        );
      }}
    </Mutation>
  );
};

export default TextEditor;
