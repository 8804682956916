import "./ToggleIndicator.css";

const ToggleIndicator = ({ isExpanded, type = "checkbox", className }) => {
  if (type === "checkbox") {
    return (
      <input
        type="checkbox"
        checked={isExpanded}
        className={`mr-2 ${className}`}
        onChange={(e) => {}}
      ></input>
    );
  }

  return isExpanded ? (
    <i className="material-icons toogleIndicatorCaret mr-2">arrow_drop_down</i>
  ) : (
    <i className="material-icons toogleIndicatorCaret mr-2">arrow_right</i>
  );
};

export default ToggleIndicator;
